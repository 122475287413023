import { Injectable } from '@angular/core';
import { TenantApiService } from '@api/tenant/tenant.api.service';
import { Observable, of } from 'rxjs';
import { WindowService } from '@services/window.service';
import { Constants } from '@utils/Constants';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TenantRepository {
  public constructor(
    public readonly tenantApi: TenantApiService,
    public windowService: WindowService,
  ) {}

  public getTenantLogo(): Observable<string> {
    const subdomain = this.windowService.getSubdomain();

    if (!subdomain) {
      return of(Constants.MASTER_TENANT_LOGO);
    }

    return this.tenantApi.getTenantLogo(subdomain).pipe(catchError(() => of(Constants.MASTER_TENANT_LOGO)));
  }

  public uploadLoginImage(tenantId: string, file: File): Observable<void> {
    return this.tenantApi.uploadLoginImage(tenantId, file);
  }

  public deleteLoginImage(tenantId: string): Observable<void> {
    return this.tenantApi.deleteLoginImage(tenantId);
  }
}
