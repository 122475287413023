import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { processImage } from '@api/utils/process-image.util';
import { buildUrl } from '@utils/url/url.util';
import { RESTServerRoute } from '../../types/Server';

@Injectable()
export class TenantApiService {
  public constructor(private httpClient: HttpClient) {}

  public getTenantLogo(subdomain: string): Observable<string> {
    const params = { Subdomain: subdomain };
    return this.httpClient
      .get<Blob>(RESTServerRoute.REST_TENANT_LOGO, {
        responseType: 'blob' as 'json',
        params,
      })
      .pipe(switchMap((blob: Blob) => processImage(blob)));
  }

  public uploadLoginImage(tenantId: string, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('image', file, file.name);

    const url = buildUrl(RESTServerRoute.REST_TENANT_UPDATE_LOGIN_IMAGE, { id: tenantId });
    return this.httpClient.post<void>(url, formData);
  }

  public deleteLoginImage(tenantId: string): Observable<void> {
    const url = buildUrl(RESTServerRoute.REST_TENANT_UPDATE_LOGIN_IMAGE, { id: tenantId });

    return this.httpClient.delete<void>(url);
  }
}
